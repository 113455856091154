@import "theme.less";

.applicationPage {
  @apply flex flex-row flex-grow items-stretch h-screen overflow-y-hidden;
}

.applicationPageComponent {
  @apply pt-4 pb-4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}

.sidebar {
  @apply flex items-stretch h-full;
}

.rightbar {
  @apply flex flex-col flex-grow justify-start h-full px-4 pt-3;
  width: calc(100vw - 16rem);
  min-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.coloredMenuItem {
  i {
    transform: scale3d(1.2, 1.2, 1);
  }
  svg {
    fill: inherit !important;
  }
}

.menuItem {
  i {
    transition: transform 0.1s ease-in-out;
  }
}

.menuItem:hover {
  .coloredMenuItem();
}

.selectedMenuItem {
  .coloredMenuItem();
  border-left: 1px solid @splitBorder;
  border-top: 1px solid @splitBorder;
  border-bottom: 1px solid @splitBorder;
}

.sideBarContent {
  @apply flex flex-col justify-between h-full;
  width: 100%;
  min-width: 100%;
  background-color: @bgPrimary;
}

.sideBarContent_item__withRightBorder {
  border-right: 1px solid @splitBorder;
}

.sideBarContent :global .ant-menu {
}

.sideBarContent :global .ant-menu-item-selected {
  border-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  color: @link-color;

  a {
    font-weight: bold !important;
    color: @link-color;
  }
}

.sideBarContent :global .ant-menu-item {
  width: auto;
  margin-left: 12px;
  padding-left: 12px !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent :global .ant-menu-item::after {
  border-right: 0 !important;
}

.sideBarContent :global .ant-menu-item-active {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent :global .ant-menu-item-selected.ant-menu-item-active {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent_applicationMenu {
  margin-right: -1px;
  background-color: @bgPrimary;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.emailHighlight {
  color: @warning;
}

.expandButton:not(:hover):not(:active):not(:active:focus) {
  color: @secondaryText !important;
}

@bgPrimary: rgb(17, 24, 39);@bgSecondary: rgb(31, 41, 55);@bgComponent: rgb(26, 35, 50);@bgTableHeader: @bgComponent;@splitBorder: #394e5a;@primary: rgb(91, 20, 250);@link: rgb(135, 138, 252);@success: rgb(11, 173, 81);@successHover: #0aa14b;@successActive: #2cc56f;@warning: #ffc021;@error: #e53935;@heading: #c1c9d2;@text: #e5e7eb;@textPale: #ffffff;@secondaryText: #7996a9;@disabled: #415969;@secondaryBorder: #415969;@secondaryBorderHover: #878afc;@secondaryBorderFocus: #5b14fa;@background-color-light: @bgComponent;@primary-background: @bgPrimary;@secondary-background: @bgSecondary;@component-background: @bgComponent;@popover-background: @bgComponent;@link-background: @link;@table-header-bg: @bgTableHeader;@table-row-hover-bg: @bgPrimary;@table-header-sort-active-bg: @bgTableHeader;@table-header-sort-bg: @bgTableHeader;@border-color-split: @splitBorder;@primary-color: @primary;@primary-5: @link;@link-color: @link;@success-color: @success;@warning-color: @warning;@error-color: @error;@heading-color: @heading;@text-color: @text;@text-color-secondary: @secondaryText;@disabled-color: @disabled;@border-color-base: @secondaryBorder;@border-hover-color-base: @secondaryBorderHover;@border-focus-color-base: @secondaryBorderFocus;@divider-color: @secondaryBorder;@alert-success-bg-color: @success-color;@alert-warning-bg-color: @warning-color;@alert-error-bg-color: @error-color;@alert-text-color: @bgPrimary;@alert-message-color: @bgPrimary;@border-radius-base: 6px;@font-size-base: 14px;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);@large-border-radius: 12px;@small-border-radius: 6px;@font-main: "system-ui", sans-serif;@font-monospace: "Source Code Pro", monospace;@font-heading: "Nunito Sans", sans-serif;@default-transition-duration: 200ms;@default-transition-delay: 0ms;@default-transition-animation: linear;@default-transiton: @default-transition-duration @default-transition-animation @default-transition-delay;